// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Styles.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Assets/CSS/PrimaryTabbed.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Assets/CSS/Textfields.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Assets/CSS/Accordian.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Assets/CSS/ReactCarousel.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Assets/CSS/Solutions.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --black: #000;\n  --white: #fff;\n  --gry: #6d6d6d;\n  --grn: green;\n  --red: #f00;\n  --yellow: #fca510;\n\n  --col1: #252525;\n  --col2: #d3ab6e;\n  --content: #696969;\n  --secbg2: #f2f2f2;\n}\n", "",{"version":3,"sources":["webpack://./src/Root.css"],"names":[],"mappings":"AAOA;EACE,aAAa;EACb,aAAa;EACb,cAAc;EACd,YAAY;EACZ,WAAW;EACX,iBAAiB;;EAEjB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":["@import url(\"./Styles.css\");\n@import url(\"./Assets/CSS/PrimaryTabbed.css\");\n@import url(\"./Assets/CSS/Textfields.css\");\n@import url(\"./Assets/CSS/Accordian.css\");\n@import url(\"./Assets/CSS/ReactCarousel.css\");\n@import url(\"./Assets/CSS/Solutions.css\");\n\n:root {\n  --black: #000;\n  --white: #fff;\n  --gry: #6d6d6d;\n  --grn: green;\n  --red: #f00;\n  --yellow: #fca510;\n\n  --col1: #252525;\n  --col2: #d3ab6e;\n  --content: #696969;\n  --secbg2: #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
